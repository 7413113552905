<template>
  <div>
    <b-loading :is-full-page="true" :active="lesson==null"></b-loading>
    <b-notification
          type="is-warning"
          role="alert"
          v-if="lesson!=null&&lesson.quiz_report_mode==='protocol'"
          :closable="false">
        {{ $t('lesson.scoreIsSaved') }}
      </b-notification>
      <div
          v-if="lesson != null"
          class="card is-shadowless is-bordered"
          style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px;"
      >
        <h2 class="subtitle has-text-weight-semibold">
          <span v-if="!isEditingName" style="margin-right: 8px">{{
              lesson.name
            }}</span><span
            v-if="!isEditingName&&isQuizStarted&&lesson.quiz_report_mode==='protocol'">({{ Math.floor(quizTime / 60) }} min. {{
            quizTime - (Math.floor(quizTime / 60)) * 60
          }} s.)</span>
          <input
              class="input is-small"
              type="text"
              :placeholder="$tc('lesson.lessonName')"
              v-model="lesson.name"
              v-if="isEditingName"
              required
              style="width: 50%; margin-right: 8px"
          />
          <small
              class="is-size-7 has-text-info link"
              @click="isEditingName = true"
              v-if="!isEditingName && !isInRemovalMode && $api.canEdit()"
          >{{ $t('lesson.edit') }}</small
          >
          <small
              class="is-size-7 has-text-success link"
              @click="save"
              v-if="isEditingName"
          >{{ $t('lesson.save') }}</small
          >
          <small
              class="is-size-7 has-text-danger link"
              style="margin-left: 8px"
              @click="
              isInRemovalMode = true;
              isEditingName = false;
            "
              v-if="!isEditingName && !isInRemovalMode && $api.canEdit()"
          >{{ $t('lesson.destroy') }}</small
          >
          <small
              class="is-size-7 has-text-danger link"
              style="margin-left: 8px"
              @click="deleteLesson"
              v-if="!isEditingName && isInRemovalMode"
          >
            {{ $t('lesson.confirmDestroy') }}</small
          >
          <small
              class="is-size-7 has-text-primary link"
              style="margin-left: 8px"
              @click="isInRemovalMode = false"
              v-if="!isEditingName && isInRemovalMode"
          >{{ $t('lesson.cancel') }}</small
          >
          <div class="select is-small" style="margin-left: 8px" v-if="$api.canEdit()">
            <select v-model="lesson.type" @change="reloadIfWysiwyg" style="float: left">
              <option value="wysiwyg">{{ $t('lesson.classicalText') }}</option>
              <option value="pdf_file">{{ $t('lesson.pdfFile') }}</option>
              <option value="paginated_pdf_file">{{ $t('lesson.paginatedPdfFile') }}</option>
              <option value="quiz">{{ $t('lesson.quiz') }}</option>
            </select>
            <select v-model="lesson.quiz_report_mode" style="float: left; margin-left:8px;" v-if="lesson.type==='quiz'">
              <option value="off">{{ $t('lesson.protocolDisabled') }}</option>
              <option value="protocol">{{ $t('lesson.saveToProtocol') }}</option>
            </select>
          </div>
          <b-button
              class="is-small"
              rounded
              style="float: right"
              :disabled="pageNumber + 1 == pagesCount"
              @click="goToNextPage"
              v-if="lesson.type=='paginated_pdf_file'"
          >{{ $t('lesson.nextPage') }}
          </b-button
          >
          <p style="float: right; margin-right: 6px;" v-if="lesson.type=='paginated_pdf_file'">{{
              pageNumber + 1
            }}/{{ pagesCount }}</p>
          <b-button
              class="is-small mr-2"
              rounded
              style="float: right"
              @click="goToPreviousPage"
              :disabled="pageNumber == 0"
              v-if="lesson.type=='paginated_pdf_file'"
          >{{ $t('lesson.previousPage') }}
          </b-button
          >
          <span style="float: right; margin-right: 8px; font-size: 20px;"
                class="has-text-weight-normal mobile-visible"
                v-if="!isQuizFinished && !$api.canEdit()&&lesson.type==='quiz'">{{ $t('lesson.question') }} {{
              quizQuestionsIndex + 1
            }}/{{ quizQuestions.length }}</span>
          <b-button
              class="is-small"
              rounded
              style="float: right"
              :disabled="quizQuestionsIndex + 1 === quizQuestions.length"
              @click="quizQuestionsIndex++"
              v-if="lesson.type==='quiz' && !isQuizFinished && !$api.canEdit()"
          >{{ $t('lesson.nextQuestion') }}
          </b-button
          >
          <span style="float: right; margin-right: 8px; font-size: 20px;"
                class="has-text-weight-normal desktop-visible"
                v-if="!isQuizFinished && !$api.canEdit()&&lesson.type==='quiz'">Pytanie {{
              quizQuestionsIndex + 1
            }}/{{ quizQuestions.length }}</span>
          <b-button
              class="is-small mr-2  previous-question-button"
              rounded
              style="float: right"
              @click="quizQuestionsIndex--"
              :disabled="quizQuestionsIndex == 0"
              v-if="lesson.type==='quiz'&& !isQuizFinished && !$api.canEdit()"
          >{{ $t('lesson.previousQuestion') }}
          </b-button
          >
          <b-button
              class="is-small mr-2"
              rounded
              style="float: right"
              @click="resetQuiz"
              v-if="lesson.type==='quiz'&& isQuizFinished && lesson.quiz_report_mode!=='protocol'"
          >{{ $t('lesson.restartQuiz') }}
          </b-button
          >
        </h2>
        <!--        <div id="trumbowyg" v-if="lesson.type == 'wysiwyg'"></div>-->
        <div id="trumbowyg" v-if="lesson.type!=='quiz'"></div>
        <div
            class="has-text-success link"
            @click="save"
            v-if="$api.canEdit() && lesson.type!=='quiz'"
            style="margin-top: 8px;"
        >{{ $t('lesson.save') }}
        </div
        >
        <b-field
            style="width: 100%; margin-top: 24px;"
            v-if="
            (lesson.type == 'pdf_file' || lesson.type == 'paginated_pdf_file')&&$api.canEdit()
          "
        >
          <b-upload
              v-model="files"
              multiple
              drag-drop
              style="width: 100%"
              accept="application/pdf"
              :disabled="isUploading"
              @input="uploadFile"
              expanded
          >
            <section class="section">
              <div class="content has-text-centered">
                <p>📁</p>
                <p>{{ $t('lesson.choosePDF') }}</p>
                <span class="has-text-weight-bold subtitle" v-if="isUploading"
                >{{ $t('lesson.uploading') }}</span
                >
              </div>
            </section>
          </b-upload>
        </b-field>
        <embed
            :src="$api.config.asset_endpoint + lesson.resource_url + '#toolbar=0&navpanes=0&scrollbar=0'"
            v-if="lesson.type === 'pdf_file' && lesson.resource_url!=null && lesson.resource_url.length>0"
            type="application/pdf"
            style="width: 100%; height: 100vh"
        />

        <!-- <iframe
          :src="lesson.resource_url+'#toolbar=0&navpanes=0&scrollbar=0&page=2'"
          v-if="lesson.type == 'paginated_pdf_file'"
          type="application/pdf"
          style="width: 100%; height: 100vh; overflow: hidden; pointer-events: none;"
          scrolling="no"
        /> -->

        <p v-html="lesson.content" v-if="!$api.canEdit() && lesson.type!=='quiz' && !lesson.content.includes('<hr>')"
           style="margin-bottom: 8px;"></p>
        <p v-html="lesson.content.split('<hr>')[currentTextPage]"
           v-if="!$api.canEdit() && lesson.type!=='quiz'  && lesson.content.includes('<hr>')"
           style="margin-bottom: 8px;"></p>
        <div style="padding-bottom: 24px;">
          <a v-if="!$api.canEdit() && lesson.type!=='quiz' && lesson.content.includes('<hr>')"
             style="margin-left: 8px; float: right; "
             @click="currentTextPage++"
             :class="{'disabled-link': currentTextPage===(lesson.content.split('<hr>')).length - 1}">Dalej</a>
          <a v-if="!$api.canEdit() && lesson.type!=='quiz' && lesson.content.includes('<hr>')"
             :class="{'disabled-link': currentTextPage===0}" @click="currentTextPage--"
             style="float:right; ">Wstecz</a>
        </div>
        <img
            :src="$api.config.asset_endpoint + pdfImagesUrl + pageNumber + '.png'"
            v-if="lesson.type === 'paginated_pdf_file' && pdfImagesUrl.length>0"
        />
        <span
            v-if="(lesson.type === 'pdf_file'  || lesson.type==='paginated_pdf_file') && lesson.resource_url!=null && lesson.resource_url.length>0 && $api.canEdit()"><a
            :href="$api.config.asset_endpoint + lesson.resource_url" target="_blank">{{
            $t('lesson.downloadOriginal')
          }}</a><br/></span>
        <div style="margin-top: -18px;" v-if="$api.canEdit() && lesson.type==='quiz'">
          <div class="columns">
            <div class="column">
              <b-field :label="$tc('lesson.categories')">
                <div v-for="category in questionCategories" :key="category.category">
                  <b-checkbox v-model="checkedCategories" :native-value="category.category">{{
                      category.category
                    }}
                  </b-checkbox>
                  <br/>
                </div>
              </b-field>
            </div>
            <div class="column">
              <b-field :label="$tc('lesson.questionCount')">
                <b-numberinput :placeholder="$tc('lesson.questionCount')" class="control"
                               v-model="lesson.quiz_questions_count"/>
              </b-field>
              <b-field :label="$tc('lesson.protocolLabel')"
                       v-if="lesson.type==='quiz'&&lesson.quiz_report_mode==='protocol' ">
                <b-input :placeholder="$tc('lesson.protocolLabel')" class="control" v-model="lesson.quiz_label"/>
              </b-field>
              <b-field :label="$tc('lesson.timeLimit')"
                       v-if="lesson.type==='quiz'&&lesson.quiz_report_mode==='protocol' ">
                <b-numberinput :placeholder="$tc('lesson.timeLimit')" class="control" v-model="lesson.quiz_time_limit"/>
              </b-field>
            </div>
          </div>
          <small
              class="button is-success link"
              @click="save"
              v-if="(lesson.type === 'quiz') && $api.canEdit()"
          >{{ $t('lesson.save') }}</small
          >
        </div>
        <!--        <div style="margin-top: -18px;" v-if="!$api.canEdit()">-->
        <!--          <div v-for="question in quizQuestions">-->
        <!--            <div :label="question.question_text">-->
        <!--              <span class="title is-6">{{ question.question_text }}</span>-->
        <!--              <b-field v-for="opt in ['a', 'b', 'c', 'd']" style="margin-top: 8px;">-->
        <!--                <b-radio :name="question.id.toString()"-->
        <!--                         :native-value="opt"-->
        <!--                         v-model="question.answer">-->
        <!--                  <span v-html="question['answer_'+opt+'_text']"></span>-->
        <!--                </b-radio>-->
        <!--              </b-field>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div style="margin-top: -18px;" v-if="!$api.canEdit()&&!isQuizFinished&&!isQuizStarted&&lesson.type==='quiz'">
          <div class="has-text-centered" style="margin-top: 48px;">
            <button class="button is-medium is-success" @click="launchQuiz" style="margin-bottom: 16px;"
                    :disabled="quizWritten">
              {{ $t('lesson.startQuiz') }}
            </button>
            <span v-if="quizWritten"><br/>{{ $t('lesson.tooManyAttempts') }}</span>
          </div>
        </div>
        <div style="margin-top: -18px;" v-if="!$api.canEdit()&&!isQuizFinished&&isQuizStarted&&lesson.type==='quiz'">
          <div
              style="margin-bottom: 8px; padding-top: 16px; font-style: italic; text-align: justify; text-justify: inter-word;">
            {{ quizQuestions[quizQuestionsIndex].question_text }}
          </div>
          <div v-for="opt in ['a', 'b', 'c', 'd']"
               :class="{card:true, 'bumped-on-hover':true,'answer-box':true,
               'answer-box-selected':quizQuestions[quizQuestionsIndex].answer[opt]}"
               @click="changeAnswer(quizQuestionsIndex, opt)">{{
              opt.toUpperCase()
            }}. {{ quizQuestions[quizQuestionsIndex]['answer_' + opt + '_text'] }}
          </div>
          <div class="has-text-centered" style="margin-top: 24px;" v-if="quizQuestionsIndex+1===quizQuestions.length">
            <button class="button is-medium is-success" @click="finishQuiz" style="margin-bottom: 16px;"
                    :disabled="quizQuestions[quizQuestions.length-1].answer.length===0">{{ $t('lesson.finishQuiz') }}
            </button>
          </div>
        </div>
        <div style="margin-top: -18px;" v-if="!$api.canEdit()&&isQuizFinished&&lesson.type==='quiz'">
          <span
              class="subtitle is-4">Wynik: {{ correctAnswersCount }}/{{
              quizQuestions.length
            }} ({{ Math.round(correctAnswersCount / quizQuestions.length * 100) }}%)</span>
          <div v-for="question in quizQuestions">
            <div style="margin-bottom: 8px; padding-top: 16px; font-style: italic">
              {{ question.question_text }}
            </div>
            <div v-for="opt in ['a', 'b', 'c', 'd']"
                 :class="{card:true, 'bumped-on-hover':true,'answer-box':true,
                 'answer-box-correct':question[`answer_${opt}_status`]===1&&question.answer[opt],
                 'answer-box-error':(question[`answer_${opt}_status`]===0&&question.answer[opt]) || (question[`answer_${opt}_status`]===1 && !question.answer[opt]),}">
              {{
                opt.toUpperCase()
              }}. {{ question['answer_' + opt + '_text'] }}
            </div>
            <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger>
                <b-button
                    :label="$tc('lesson.explanation')"
                    type="is-primary is-small"
                    aria-controls="contentIdForA11y1"/>
              </template>
              <div class="notification">
                <div class="content">
                  <p v-html="question.explanation" v-if="question.explanation!=null && question.explanation.length>0">
                  </p>
                  <p v-else>
                    {{ $t('lesson.noExplanation') }}
                  </p>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";

export default {
  name: "Lesson",
  components: {HomeWrapper},
  mounted() {
    this.load();
  },
  computed: {
    pdfImagesUrl() {
      try {
        if (this.lesson != null && this.lesson.resource_url != null) {
          const url = this.lesson.resource_url.split("?")[0];
          const id = url.split("/").slice(-1)[0].replaceAll(".pdf", "");
          return url.split("/").slice(0, -1).join("/") + `/image-${id}-`;
        }
      } catch (err) {
        return "";
      }
      return "";
    },
    pagesCount() {
      try {
        if (this.lesson != null && this.lesson.resource_url != null)
          return parseInt(this.lesson.resource_url.split("=")[1]);
      } catch (err) {
        return "";
      }
      return 0;
    },
    correctAnswersCount() {
      let count = 0;
      for (const question of this.quizQuestions) {
        if (question[`answer_a_status`] === (question.answer['a'] ? 1 : 0)
            && question[`answer_b_status`] === (question.answer['b'] ? 1 : 0)
            && question[`answer_c_status`] === (question.answer['c'] ? 1 : 0)
            && question[`answer_d_status`] === (question.answer['d'] ? 1 : 0))
          count++;
      }
      return count;
    },
  },
  data() {
    return {
      course: null,
      chapter: null,
      lesson: null,
      isEditingName: false,
      isWysiwygLoaded: false,
      isInRemovalMode: false,
      files: [],
      pageNumber: 0,
      isUploading: false,
      questionCategories: [],
      checkedCategories: [],
      quizQuestions: [],
      quizQuestionsIndex: 0,
      isQuizFinished: false,
      isQuizStarted: false,
      quizTime: 0,
      quizTimerInterval: null,
      currentTextPage: 0,
      quizWritten: false
    };
  },
  methods: {
    goToPreviousPage() {
      if (this.pageNumber > 0) this.pageNumber--;
    },
    goToNextPage() {
      if (this.pageNumber < parseInt(this.lesson.resource_url.split("=")[1]))
        this.pageNumber++;
    },
    changeAnswer(quizQuestionsIndex, opt) {
      this.quizQuestions[quizQuestionsIndex].answer[opt] = !this.quizQuestions[quizQuestionsIndex].answer[opt];
    },
    async load() {
      this.course = await this.$api.getCourse(this.$route.params.course_id);
      this.$api.sidebar.course = this.course;
      this.$api.sidebar.chapters = await this.$api.getChapters(this.course.id);
      this.chapter = await this.$api.getChapter(
          this.course.id,
          this.$route.params.chapter_id
      );
      this.lesson = await this.$api.getLesson(
          this.course.id,
          this.$route.params.chapter_id,
          this.$route.params.lesson_id
      );
      if (this.lesson.content == null) this.lesson.content = '';
      if (this.lesson.quiz_questions_count === 0) this.lesson.quiz_questions_count = 10;
      if (this.lesson.quiz_categories) this.checkedCategories = this.lesson.quiz_categories.split(';');
      if (this.lesson.type === 'quiz' && !this.$api.canEdit()) {
        this.quizWritten = (await this.$api.checkScore(this.course.id, this.lesson.id)).count > 0;
        this.quizQuestions = (await this.$api.getQuestionsByCategories(this.lesson.quiz_categories.split(';'), this.lesson.quiz_questions_count)).map(q => {
          q.answer = {};
          for (const opt of ['a', 'b', 'c', 'd'])
            q.answer[opt] = false;
          return q;
        });
      }

      const self = this;
      if (this.lesson.type === 'quiz') {
        this.questionCategories = await this.$api.getQuestionCategories();
      }
      this.$api.setupTracker(this);
      // if (!this.isWysiwygLoaded && this.lesson.type === "wysiwyg" && this.$api.canEdit())
      if (!this.isWysiwygLoaded && this.$api.canEdit())
        setTimeout(() => {
          self.isWysiwygLoaded = true;
          require("./../assets/upload.js");
          jQuery("#trumbowyg").trumbowyg({
            autogrow: true,
            removeformatPasted: true,
            btns: [
              ["viewHTML"],
              ["undo", "redo"], // Only supported in Blink browsers
              ["formatting"],
              ["strong", "em", "del"],
              ["superscript", "subscript"],
              ["link"],
              ["insertImage", "upload"],
              ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
              ["unorderedList", "orderedList"],
              ["horizontalRule"],
              ["removeformat"],
              ["fullscreen"],
            ],
            plugins: {
              upload: {
                serverPath: `${self.$api.config.endpoint}uploads-wysiwyg`,
                fileFieldName: "asset",
                urlPropertyName: "url",
              },
            },
          });
          jQuery("#trumbowyg").trumbowyg("html", self.lesson.content);
        }, 100);

      // const chapterTime = await this.$api.getChapterTime(this.course.id, this.chapter.id);
      const courseId = 1;
      const chapterTimes = {
        // 1: (await this.$api.getChapterTime(courseId, 1)),
        101: (await this.$api.getChapterTime(courseId, 101)),
        104: (await this.$api.getChapterTime(courseId, 104)),
        103: (await this.$api.getChapterTime(courseId, 103)),
        100: (await this.$api.getChapterTime(courseId, 100)),
        97: (await this.$api.getChapterTime(courseId, 97)),
        99: (await this.$api.getChapterTime(courseId, 99)),
        98: (await this.$api.getChapterTime(courseId, 98)),
        102: (await this.$api.getChapterTime(courseId, 102)),
        105: (await this.$api.getChapterTime(courseId, 105)),
      };
      const chapterRequiredTimes = {
        // 1: 60 * 60,
        101: 14 * 3600,
        104: 16 * 3600,
        103: 14 * 3600,
        100: 8 * 3600,
        97: 6 * 3600,
        99: 14 * 3600,
        98: 14 * 3600,
        102: 8 * 3600,
        105: 6 * 3600,
      };
      const chapterPartialExamLessons = {
        // 1: 3,
        101: 1692,
        104: 1693,
        103: 1694,
        100: 1695,
        97: 1696,
        99: 1698,
        98: 1697,
        102: 1699,
        105: 1700,
      };
      for (const chapterId in chapterTimes) {
        if (chapterTimes[chapterId] > chapterRequiredTimes[chapterId] && !((await this.$api.checkScore(this.course.id, chapterPartialExamLessons[chapterId])).count > 0) && chapterPartialExamLessons[chapterId] !== this.lesson.id) {
          if (!this.$api.canEdit() && !this.$api.user.is_inspector) {
            this.$buefy.dialog.alert({
              title: 'Czas na egzamin cząstkowy',
              message: 'Podejmij wyzwanie i sprawdź swoją wiedzę, którą już przyswoiłeś. Po zakończonym teście cząstkowym możesz kontynuować naukę. Powodzenia!',
              confirmText: 'Przejdź do egzaminu',
              canCancel: ['button']
            });
            this.$router.push(`/courses/${this.course.id}/chapters/${chapterId}/lessons/${chapterPartialExamLessons[chapterId]}`);
          }
        }
      }
      let failedPartial = false;
      for (const examId of Object.values(chapterPartialExamLessons)) {
        if (!((await this.$api.checkScore(this.course.id, examId)).count > 0)) failedPartial = true;
      }
      if (failedPartial && this.$route.params.chapter_id == 3 && !this.$api.canEdit() && !this.$api.user.is_inspector) {
        this.$buefy.dialog.alert({
          title: 'Uwaga',
          message: 'Obecnie nie możesz przejść do egzaminu końcowego. Musisz najpierw zdać wszystkie egzaminy cząstkowe.',
          confirmText: 'Rozumiem',
          canCancel: ['button']
        });
        this.$router.go(-1);
      }
      this.$forceUpdate();
    },
    async save() {
      this.lesson.content = jQuery("#trumbowyg").trumbowyg("html");
      this.lesson.quiz_categories = this.checkedCategories.join(';');
      if (this.lesson.name.length > 0) {
        await this.$api.editLesson(
            this.course.id,
            this.chapter.id,
            this.lesson
        );
      } else {
        this.lesson = await this.$api.getLesson(
            this.course.id,
            this.$route.params.chapter_id,
            this.$route.params.lesson_id
        );
      }
      this.isEditingName = false;
      this.$buefy.toast.open({
        duration: 1000,
        message: 'Zapisano!',
        position: 'is-top',
        type: 'is-success'
      })
    }
    ,
    async reloadIfWysiwyg() {
      const lesson = await this.$api.getLesson(
          this.course.id,
          this.$route.params.chapter_id,
          this.$route.params.lesson_id
      );
      const oldType = lesson.type;
      lesson.type = this.lesson.type;
      await this.$api.editLesson(this.course.id, this.chapter.id, lesson);
      if (!(this.lesson.type.includes("pdf") && oldType.includes("pdf")))
        window.location.reload();
    }
    ,
    async uploadFile() {
      this.isUploading = true;
      const result = await this.$api.uploadAsset(
          this.files[0],
          this.lesson.type == "paginated_pdf_file"
      );
      this.lesson.resource_url = result;
      await this.save();
      this.isUploading = false;
    },
    async deleteLesson() {
      await this.$api.deleteLesson(
          this.course.id,
          this.chapter.id,
          this.lesson
      );
      this.$router.push(
          "/courses/" + this.course.id + "/chapters/" + this.chapter.id
      );
    },
    resetQuiz() {
      this.load();
      this.quizQuestionsIndex = 0;
      this.isQuizFinished = false;
      this.isQuizStarted = false;
    },
    async finishQuiz() {
      this.isQuizFinished = true;
      const time = this.quizTime;
      clearInterval(this.quizTimerInterval);
      let count = 0;
      for (const question of this.quizQuestions) {
        if (question[`answer_a_status`] === (question.answer['a'] ? 1 : 0)
            && question[`answer_b_status`] === (question.answer['b'] ? 1 : 0)
            && question[`answer_c_status`] === (question.answer['c'] ? 1 : 0)
            && question[`answer_d_status`] === (question.answer['d'] ? 1 : 0))
          count++;
      }
      if (this.lesson.quiz_report_mode === 'protocol') {
        await this.$api.saveScore(Math.round(count / this.quizQuestions.length * 100), time, this.course.id, this.lesson.id);
      }
    },
    launchQuiz() {
      this.isQuizStarted = true;
      this.quizTime = this.lesson.quiz_time_limit * 60;
      this.quizTimerInterval = setInterval(() => {
        this.quizTime--;
        if (this.quizTime === 0) {
          this.finishQuiz();
        }
      }, 1000);
    }
  },
};
</script>

<style scoped>
.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.bumped-on-hover:hover {
  filter: brightness(90%);
}

.answer-box {
  margin-bottom: 8px;
  padding: 8px;
  cursor: pointer;
}

.answer-box-correct {
  background: rgba(72, 199, 142, 0.8);
  overflow: hidden;
  z-index: 2;
}

.answer-box-error {
  background: rgba(241, 70, 104, 0.8);
  overflow: hidden;
  z-index: 2;
}

.answer-box-selected {
  background: rgba(52, 115, 220, 0.8);
  overflow: hidden;
  z-index: 2;
}

@media screen and (min-width: 600px) {
  .mobile-visible {
    display: none;
  }

  .desktop-visible {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .mobile-visible {
    display: block;
  }

  .desktop-visible {
    display: none;
  }

  .previous-question-button {
    float: left !important;
  }
}

.disabled-link {
  opacity: 0.4;
  pointer-events: none;
}
</style>
